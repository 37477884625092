.slider {
  // У нас есть отдельный класс container для обертки контента

  padding-left: 101px;
  padding-right: 97px;
  max-width: 1920px;
  margin: auto;

  @include viewport-tablet--m {
    padding-left: 70px;
    padding-right: 65px;
  }

  @include viewport-tablet--s {
    padding-left: 10px;
    padding-right: 10px;
  }

  &--index {
    padding: 0;
    margin: 0;
    max-width: unset;

    @include viewport-tablet--m {
      padding: 0;
    }

    @include viewport-tablet--s {
      padding: 0;
    }

    @include viewport-mobile {
      padding-top: 55px;
    }
  }

  &--news {
    padding-bottom: 160px;
    position: relative;
  }
}

.slider__head {
  font-size: 70px;
  line-height: 90%;
  font-family: $font-family-saira-condensed;
  font-weight: 500;
  text-transform: uppercase;
  color: $color-nero;
  white-space: nowrap;
  margin-top: 85px;
  margin-bottom: 51px;

  @include viewport-tablet--m {
    font-size: 45px;
  }

  @include viewport-tablet--s {
    font-size: 30px;
    margin-bottom: 14px;
  }
}

.slider__navigation {
  position: absolute;
  top: -91px;
  right: 0;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 108px;

  @include viewport-tablet--s {
    right: 0;
    top: -34px;
  }
}

.slider__navigation-btn {
  transition: all $default-transition-settings;
  cursor: pointer;

  background-color: transparent;
  border: none;
  font-size: 0;
  width: 43px;
  height: 16px;

  &--last {
    span {
      display: block;
      background-image: url("../img/svg/slide-left.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      height: 16px;
      width: 43px;
      margin-left: -7px;
    }
  }

  &--next {
    span {
      display: block;
      background-image: url("../img/svg/slide-left.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      transform: rotate(180deg);
      width: 43px;
      height: 16px;
      margin-top: -2px;
      margin-left: -5px;
    }

    position: relative;

    &::after {
      z-index: -8;
      top: 0;
      left: -6px;
      width: 16px;
      height: 16px;
      content: "";
      position: absolute;
      border-radius: 50%;
      background-color: $color-bahama-blue;
      transition: all $default-transition-settings;
    }

    &:hover::after {
      background-color: $color-rust;
    }
  }

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.6;
  }
}

.slider__wrapper {
  position: relative;
}
