.news-list {
  padding: 0;
}

.news-list__item {
  list-style: none;
  display: grid;
  height: auto;
  grid-template-rows: 320px minmax(62px, 1fr) min-content min-content;

  @include viewport-tablet--s {
    grid-template-rows: 175.8px minmax(62px, 1fr) repeat(2, max-content);
  }

  h3 {
    font-family: $font-family-saira-condensed;
    font-weight: 500;
    font-size: 26px;
    line-height: 100%;
    margin-top: 29px;
    max-width: 92%;
    margin-bottom: 17px;
    text-transform: uppercase;

    @include viewport-tablet--s {
      margin-top: 15px;
      font-size: 20px;
      max-width: 100%;
      margin-bottom: 2px;
    }
  }

  h3 a {
    color: $color-nero;
    transition: all $default-transition-settings;

    @include viewport-tablet--s {
      color: $color-bahama-blue;
    }

    &:hover {
      cursor: pointer;
      color: $color-bahama-blue;
    }
  }

  p {
    font-family: $font-family-roboto;
    color: $color-nero;
    opacity: 0.7;
    letter-spacing: 0.03em;
    line-height: 150%;
    font-size: 16px;
    margin-bottom: 40px;

    @include viewport-tablet--s {
      font-size: 14px;
      margin-bottom: 37px;
    }
  }
}

.news-list__image {
  grid-row: 1 / 2;

  @include viewport-tablet--s {
    height: auto;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
