.order-form {
  display: flex;
  flex-direction: column;

  background-color: $color-rust;

  @include viewport-desktop--s {
    padding-left: 0;
  }

  @include viewport-tablet--s {
    align-items: center;
  }
}

.order-form__title {
  font-family: $font-family-saira-condensed;
  font-weight: 500;

  margin-top: 0;
  margin-bottom: 2.2vw;

  text-transform: uppercase;
  color: $color-default-white;

  @include viewport-desktop--l {
    margin-bottom: 42px;
  }

  @include viewport-tablet--s {
    margin-bottom: 8vw;
  }
}

.order-form__form {
  display: flex;
  align-items: center;

  box-sizing: border-box;

  label {
    margin-right: 2vw;

    transition: $default-transition-settings;

    &:hover input {
      background-color: $color-white-smoke;
    }
  }

  input {
    min-width: 300px;
    min-height: 60px;

    padding: 10px;

    border: none;
    background-color: $color-default-white;

    transition: $default-transition-settings;
  }

  @include viewport-tablet--s {
    flex-direction: column;
    align-items: center;

    label {
      margin-right: 0;
      margin-bottom: 3vw;
    }

    input {
      min-height: 36px;
      padding: 5px;
    }
  }
}

.order-form__submit {
  display: flex;
  flex-direction: column;
  justify-content: center;

  flex-shrink: 0;

  min-width: 100px;
  height: 60px;

  background: none;
  border: none;
  cursor: pointer;

  transition: $default-transition-settings;

  span {
    font-family: $font-family-saira-condensed;
    font-weight: 500;

    font-size: 20px;
    line-height: 90%;

    text-transform: uppercase;
    color: $color-default-white;
  }

  svg {
    fill: $color-default-white;
    transition: $default-transition-settings;
  }

  &:hover {
    svg {
      transform: translateX(30%);
    }
  }

  @include viewport-tablet--s {
    align-items: center;

    span {
      font-size: 4.7vw;
    }
  }

  @include viewport-mobile {
    min-height: 70px;

    span {
      font-size: 16px;
    }
  }
}
