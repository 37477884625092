@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: "Saira";

  font-display: swap;
  src:
    url("../fonts/Saira-Regular.woff2") format("woff2"),
    url("../fonts/Saira-Regular.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 500;
  font-family: "Saira Condensed";

  font-display: swap;
  src:
    url("../fonts/SairaCondensed-Medium.woff2") format("woff2"),
    url("../fonts/SairaCondensed-Medium.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 500;
  font-family: "Roboto";

  font-display: swap;
  src:
    url("../fonts/Roboto-Medium.woff2") format("woff2"),
    url("../fonts/Roboto-Medium.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: "Roboto";

  font-display: swap;
  src:
    url("../fonts/Roboto-Regular.woff2") format("woff2"),
    url("../fonts/Roboto-Regular.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 300;
  font-family: "Roboto";

  font-display: swap;
  src:
    url("../fonts/Roboto-Light.woff2") format("woff2"),
    url("../fonts/Roboto-Light.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-family: "Montserrat";

  font-display: swap;
  src:
    url("../fonts/Montserrat-SemiBold.woff2") format("woff2"),
    url("../fonts/Montserrat-SemiBold.woff") format("woff");
}
