.container {
  width: 100%;
  max-width: $container-width + 2 * $c-padding-desktop--m;
  margin: 0 auto;
  padding: 0 $c-padding-desktop--m;

  @include viewport-desktop--s {
    max-width: $container-width + 2 * $c-padding-desktop--s;
    padding: 0 $c-padding-desktop--s;
  }

  @include viewport-tablet--m {
    padding: 0 $c-padding-tablet--m;
  }

  @include viewport-tablet--s {
    padding: 0 $c-padding-tablet--s;
  }

  @include viewport-mobile {
    padding: 0 $c-padding-mobile;
  }

  &--no-padding {
    padding: 0;
  }

  &--no-padding-mobile {
    @include viewport-mobile {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
