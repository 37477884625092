.news {
  padding-left: 100px;
  padding-right: 97px;
  max-width: 1920px;
  padding-bottom: 102px;
  margin: auto;

  .preloader {
    visibility: hidden;
    height: 0;
    width: 0;
    margin: 0;
  }

  @include viewport-tablet--m {
    padding-left: 70px;
    padding-right: 65px;
  }

  @include viewport-tablet--s {
    padding-left: 0;
    padding-right: 0;
    margin-top: 0;
    padding-bottom: 45px;
  }

  h2 {
    font-family: $font-family-saira-condensed;
    color: $color-bahama-blue;
    font-size: 100px;
    font-weight: 500;
    line-height: 90%;
    text-transform: uppercase;
    margin-top: 72px;
    margin-bottom: 72px;
    max-width: 1600px;

    @include viewport-tablet--m {
      font-size: 50px;
    }

    @include viewport-tablet--s {
      font-size: 30px;
      color: $color-nero;
      margin-top: 38px;
      margin-bottom: 36px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .news-list__item {
    @include viewport-tablet--s {
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.07);
      padding: 30px 10px 41px 10px;
    }
  }
}

.news--preloader {
  .preloader {
    visibility: visible;
    width: 100%;
    margin-top: 90px;
    height: auto;

    @include viewport-tablet--s {
      margin-top: 32px;
    }
  }
}

.news__news-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 42px;
  row-gap: 69px;
  width: 100%;
  padding: 0;

  @include viewport-tablet--m {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 42px;
  }

  @include viewport-tablet--s {
    grid-template-columns: 1fr;
    row-gap: 28px;
  }

  h3 {
    margin-bottom: 18px;
  }
}
