.service-top {
  padding-left: 101px;
  padding-right: 96px;
  max-width: 1920px;
  height: 484px;
  margin: auto;
  position: relative;
  margin-top: 11px;


  @include viewport-tablet--m {
    padding-left: 70px;
    padding-right: 65px;
  }

  @include viewport-tablet--s {
    padding-left: 10px;
    padding-right: 10px;
    height: 180px;
    margin-top: 0;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.service-top__form-wrapper {
  width: 547px;
  position: absolute;
  bottom: -50px;
  right: 246px;
  z-index: 2;

  @include viewport-desktop--s {
    right: 125px;
    width: max-content;
  }

  @include viewport-tablet--s {
    position: relative;
    width: 100%;
    margin-top: -124px;
    right: 0;
    bottom: 0;
  }
}

.service-top__form {
  max-width: 547px;
  height: 453px;
  background-color: $color-rust;
  font-family: $font-family-saira-condensed;
  color: $color-default-white;
  text-transform: uppercase;
  padding-top: 68px;
  opacity: 1;
  transition: all $default-transition-settings;

  @include viewport-desktop--s {
    width: 320px;
    height: 340px;
    padding-top: 52px;
  }

  @include viewport-tablet--s {
    max-width: none;
    width: 238px;
    margin: auto;
    padding-top: 40px;
    height: 247px;
  }

  p {
    text-align: center;
    font-size: 70px;
    line-height: 90%;
    margin-top: 0;
    margin-bottom: 40px;

    @include viewport-desktop--s {
      font-size: 50px;
      margin-bottom: 30px;
    }

    @include viewport-tablet--s {
      font-size: 30px;
      margin-bottom: 26px;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    max-width: 333px;
    margin: auto;
  }

  input {
    width: 100%;
    border: none;
    height: 60px;
    font-size: 16px;
    transition: all $default-transition-settings;
    cursor: pointer;
    padding-left: 11px;
    padding-right: 12px;

    &:hover {
      opacity: 0.9;
    }

    @include viewport-desktop--s {
      height: 42px;
    }

    @include viewport-tablet--s {
      height: 26px;
    }
  }

  label {
    margin-bottom: 26px;
    font-size: 0;

    @include viewport-desktop--s {
      padding-left: 32px;
      padding-right: 33px;
    }

    @include viewport-tablet--s {
      padding-left: 46.5px;
      padding-right: 47.5px;
      margin-bottom: 6.4px;
    }
  }

  button {
    color: $color-default-white;
    background-color: transparent;
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 20px;
    text-transform: uppercase;
    margin-top: 9px;
    padding-right: 18px;
    cursor: pointer;
    transition: all $default-transition-settings;

    &:hover {
      opacity: 0.9;
    }

    @include viewport-tablet--s {
      padding-right: 24px;
      font-size: 16px;
      margin-top: 16px;
    }

    span {
      position: relative;
    }

    span::after {
      position: absolute;
      content: "";
      left: 0;
      bottom: -9px;
      background-image: url("../img/svg/buy-now.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      width: 80px;
      height: 8px;
      transition: all $default-transition-settings;
    }

    span:hover::after {
      left: 10px;
    }
  }

  @include viewport-tablet--s {
    button span::after {
      left: -1px;
      width: 77px;
    }
  }
}

.service-content {
  padding-left: 101px;
  padding-right: 96px;
  max-width: 1920px;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr max-content;
  margin-bottom: 88px;

  @include viewport-tablet--m {
    padding-left: 70px;
    padding-right: 65px;
  }

  @include viewport-tablet--s {
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 19px;
  }

  button {
    background-color: $color-rust;
    color: $color-default-white;
    width: 180px;
    height: 50px;
    border: none;
    border-radius: 30px;
    text-transform: uppercase;
    font-family: $font-family-saira-condensed;
    font-weight: 500;
    font-size: 20px;
    line-height: 90%;
    margin-top: 90px;
    background-image: url("../img/svg/buy-now.svg");
    background-repeat: no-repeat;
    background-size: 80px;
    background-position: 42.5px 35px;
    padding-bottom: 10px;
    transition: all $default-transition-settings;
    opacity: 1;
    cursor: pointer;

    &:hover {
      opacity: 0.9;
      background-position: 58px 35px;
    }

    @include viewport-tablet--s {
      visibility: hidden;
      opacity: 0;
      width: 0;
      height: 0;
      font-size: 0;
      margin: 0;
      padding: 0;
    }
  }

  h2 {
    font-family: $font-family-saira-condensed;
    font-size: 100px;
    line-height: 90%;
    font-weight: 500;
    text-transform: uppercase;
    color: $color-bahama-blue;
    margin-top: 70px;
    margin-bottom: 54px;

    @include viewport-tablet--m {
      font-size: 65px;
    }

    @include viewport-tablet--s {
      font-size: 30px;
      color: $color-nero;
      margin-top: 162px;
      margin-bottom: 37px;
    }
  }

  h3 {
    font-family: $font-family-saira-condensed;
    font-size: 26px;
    line-height: 90%;
    font-weight: 500;
    margin-bottom: 21px;
    margin-top: 0;

    &:not(:first-child) {
      margin-top: 27px;
    }
  }

  @include viewport-tablet--s {
    h3:not(:first-child) {
      margin-top: 31px;
    }
  }

  p {
    font-family: $font-family-roboto;
    letter-spacing: 0.03em;
    font-weight: 300;
    font-size: 14px;
    line-height: 164%;
    width: 80.5%;
    margin-bottom: 9px;
    margin-top: 9px;

    @include viewport-tablet--s {
      width: auto;
      line-height: 150%;
    }
  }

  a {
    text-decoration: underline;
    color: $color-bahama-blue;
    cursor: pointer;

    &:hover {
      color: $color-rust;
      transition: all $default-transition-settings;
    }
  }
}

.service-content-text {
  grid-column: 1 / 3;
  column-count: 2;
  column-gap: 37px;
  --moz-column-count: 2;
  --webkit-column-count: 2;
  min-height: 458px;

  @include viewport-tablet--s {
    column-count: auto;
  }
}

.error-service {
  outline: 4px solid $color-nero;
}
