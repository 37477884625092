.header {
  width: 100%;
  background-color: $color-default-white;
  z-index: 100;
  position: sticky;
  top: 0;

  &--top {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.07);
    left: 0;
  }

  @include viewport-tablet--m {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.07);

    &--overflow .header__menu > ul {
      overflow: auto;
      height: 100vh;
      flex-wrap: nowrap;
      z-index: 10;
      opacity: 1;
    }
  }
}

.header__wrapper {
  max-width: 1920px;
  padding-left: 100px;
  padding-right: 97px;
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  margin-left: auto;
  margin-right: auto;
  min-height: 118px;


  @include viewport-desktop--s {
    padding-left: 60px;
    padding-right: 60px;
  }

  @include viewport-tablet--m {
    grid-template-columns: 1fr min-content max-content;
    min-height: 60px;
    padding-left: 0;
    padding-right: 0;
    height: 60px;
    animation: headerHeight 0s 1s forwards;
  }
}

.header__logo {
  margin-top: 20px;
  max-width: 378px;
  width: 100%;
  cursor: pointer;
  transition: all $default-transition-settings;

  -webkit-tap-highlight-color: transparent;

  &--main {
    pointer-events: none;
  }

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.6;
  }

  @include viewport-tablet--m {
    margin-left: 10px;
    margin-top: 15px;
    height: 32px;
  }


  @include viewport-desktop--s {
    margin-top: 37px;
  }

  img {
    object-fit: contain;

    @include viewport-desktop--s {
      width: 226px;
    }


    @include viewport-tablet--m {
      width: 131px;
      margin-top: 2px;
    }
  }
}

// menu list

.header__menu-btn {
  visibility: hidden;
  background-color: transparent;
  width: 0;
  height: 0;
  padding: 0;
  border: 0;
  font-size: 0;
  cursor: pointer;
  pointer-events: all;

  -webkit-tap-highlight-color: transparent;

  @include viewport-tablet--m {
    visibility: visible;
    width: 29px;
    height: 19px;
    background-image: url("../img/svg/burger-close.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: -1px;
    margin-top: 0;
  }
}

.header__menu-wrapper {
  margin-top: 12px;
  font-family: $font-family-saira;
  padding-left: 14px;
  padding-right: 13px;


  @include viewport-tablet--m {
    order: 2;
    width: auto;
  }

  button:active,
  button:focus {
    outline: none;
  }

  button::-moz-focus-inner {
    border: 0;
  }
}

.header__menu {
  font-size: 18px;
  text-transform: uppercase;
  position: relative;


  @include viewport-desktop--s {
    font-size: 16px;
  }

  @include viewport-tablet--m {
    font-size: 18px;
  }

  > ul {
    bottom: -300px;
    width: max-content;
    margin: auto;
    padding: 0 6px 0 0;
    display: grid;
    grid-template-columns: repeat(5, max-content);
    grid-auto-rows: min-content;
    column-gap: 60px;
    letter-spacing: 0.03em;
    opacity: 1;
    transition: opacity $default-transition-settings;

    @include viewport-desktop--s {
      column-gap: 42px;
    }

    @include viewport-tablet--m {
      column-gap: 0;
      position: absolute;
      right: -13px;
      top: 33px;
      margin-top: 15px;
      width: 100vw;
      background-color: $color-rust;
      opacity: 0;
      grid-template-columns: max-content;
      row-gap: 2px;
      padding-left: 10px;
      padding-top: 90px;
      padding-bottom: 90px;
    }
  }

  > ul a {
    @include viewport-tablet--m {
      color: $color-default-white;
    }
  }

  li {
    list-style: none;
    padding: 0;
    position: relative;
    margin-top: 13px;
    margin-bottom: 13px;

    @include viewport-tablet--m {
      margin-top: 8px;
      margin-bottom: 10px;
    }
  }

  a:hover {
    color: $color-rust;

    @include viewport-tablet--m {
      color: $color-default-white;
      opacity: 0.8;
    }
  }
}

.header__side-menu {
  visibility: visible;
  opacity: 1;
  height: auto;
  transition: all $default-transition-settings;
  font-size: 16px;
  width: 256px;
  position: absolute;
  top: 38px;
  padding: 7px 18px;
  background-color: $color-default-white;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.07);
  letter-spacing: 0.03em;
  line-height: 20px;

  @include viewport-tablet--m {
    position: static;
    visibility: visible;
    opacity: 1;
    background-color: transparent;
    box-shadow: none;
    padding-left: 0;
    width: 230px;
    line-height: 16px;
    padding-bottom: 0;
    margin-bottom: -13px;
  }

  li {
    @include viewport-tablet--m {
      margin-top: 12px;
      margin-bottom: 12px;
    }
  }

  &--close {
    visibility: hidden;
    opacity: 0;
  }
}

.header__menu-link {
  -webkit-tap-highlight-color: transparent;

  &:hover + .header__side-menu,
  + .header__side-menu:hover {
    visibility: visible;
    opacity: 1;
  }

  &:hover + .header__side-menu + button,
  + .header__side-menu:hover + button {
    transform: rotate(0);
  }

  &--active {
    color: $color-rust;
    text-decoration: underline;

    @include viewport-tablet--m {
      color: $color-default-white;
    }
  }
}

.header__side-menu-btn {
  background-color: transparent;
  font-size: 0;
  background-image: url("../img/svg/side-menu-vector.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  border: 0;
  width: 9px;
  height: 4px;
  padding-bottom: 4px;
  padding-top: 4px;
  position: absolute;
  top: 7px;
  right: -19px;
  transition: all $default-transition-settings;
  transform: rotate(180deg);

  @include viewport-tablet--m {
    transform: rotate(0);
    left: -4px;
    top: -2px;
    padding-left: 95px;
    transition: none;
    border: 10px solid transparent;
    background-image: url("../img/svg/side-menu-vector-white.svg");
  }

  &--touch {
    transition: none;
    transform: rotate(0);
    left: 0;
    top: -2px;
    padding-left: 90px;
    border: 10px solid transparent;
    background-position: right;
    background-size: 12px;

    @include viewport-desktop--s {
      padding-left: 80px;
    }
  }
}

// contacts

.header__contact {
  display: grid;
  align-items: center;
  grid-template-columns: min-content 1fr;
  grid-template-rows: repeat(2, min-content);
  margin-top: 28px;
  letter-spacing: 0.03em;
  max-width: 197px;
  width: 15%;
  white-space: nowrap;
  text-align: right;
  margin-right: 4px;
  transition: all $default-transition-settings;
  -webkit-tap-highlight-color: transparent;

  @include viewport-tablet--m {
    height: 15px;
    margin-top: 14px;
    grid-template-columns: min-content 1fr;
    grid-template-rows: repeat(2, min-content);
  }

  .header__contact-number {
    font-weight: 600;
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: -4px;
    letter-spacing: 0.5px;
    font-family: $font-family-montserrat;
    text-decoration: none;

    @include viewport-desktop--s {
      font-size: 0;
      width: 0;
    }
  }

  .header__contact-adress {
    margin-top: 0;
    margin-bottom: 0;
    text-decoration: underline;
    font-size: 14px;
    letter-spacing: 0.21px;
    font-family: $font-family-roboto;

    @include viewport-desktop--s {
      font-size: 0;
      height: 0;
      visibility: hidden;
      order: 1;
      margin-top: 35px;
      margin-right: 17px;
    }
  }

  &:hover {
    color: $color-rust;

    span {
      opacity: 0.7;
    }
  }
}

.header__contact-phone {
  grid-row: 1 / 3;
  width: 29px;
  height: 29px;
  background-image: url("../img/svg/phone-head.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
  font-size: 0;
  margin-top: 2px;
  margin-right: 13px;
  transition: all $default-transition-settings;

  @include viewport-tablet--m {
    font-size: 0;
    width: 30px;
    height: 30px;
    margin-right: 0;
    margin-top: 1px;
    grid-column: 2 / 3;
    grid-row: 1 / 2;
  }
}

// menu intaractive
@include viewport-tablet--m {
  .header__menu--close {
    .header__menu-btn {
      background-image: url("../img/svg/burger-open.svg");
    }

    > ul {
      transition: opacity $default-transition-settings;
      visibility: hidden;
      opacity: 0;
    }
  }
}

.header__side-menu--close + button {
  transform: rotate(180deg);
  background-position: left;
}

@include viewport-tablet--m {
  .header__side-menu--close {
    height: 0;
    opacity: 0;
    visibility: hidden;

    + button {
      transform: rotate(180deg);
    }
  }
}

.header--no-js {
  @include viewport-tablet--m {
    .header__menu > ul {
      position: relative;
    }

    .header__menu-wrapper {
      grid-row: 2 / 3;
      grid-column: 1 / 3;
    }

    .header__menu {
      padding: 0;
    }

    .header__contact {
      margin-right: 60px;
    }
  }
}

.header__menu--no-js {
  @include viewport-tablet--m {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: -50px;
  }
}

.header__menu--no-js > ul {
  @include viewport-tablet--m {
    margin-top: -24px;
    margin-bottom: 45px;

    // Костыль на баг с мерцающим меню при загрузке страници в мобильной версии
    animation: headerVisible 0s 1s forwards;
    visibility: hidden;
    height: 0;
    opacity: 0;

    .header__side-menu {
      animation: headerVisible 0s 1s forwards;
      visibility: hidden;
      height: 0;
      opacity: 0;
    }

    .header__menu-btn {
      animation: menuImage 0s 1s forwards;
      background-image: url("../img/svg/burger-open.svg");
    }
  }
}

@keyframes headerVisible {
  to {
    visibility: visible;
    height: auto;
    opacity: 1;
  }
}

@keyframes headerHeight {
  to {
    height: auto;
  }
}

@keyframes menuImage {
  to {
    background-image: url("../img/svg/burger-close.svg");
  }
}

.header:not(.header--main) {
  .header__menu {
    padding-left: 38px;

    @include viewport-tablet--m {
      padding-left: 0;
    }
  }

  .header__wrapper {
    padding-right: 94px;
    padding-left: 103px;

    @include viewport-tablet--m {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .header__logo {
    max-width: 346px;
    margin-top: 27px;

    @include viewport-desktop--s {
      margin-top: 37px;
    }

    @include viewport-tablet--m {
      margin-top: 15px;
    }
  }
}
