.news-block {
  padding-left: 100px;
  padding-right: 96px;
  max-width: 1920px;
  margin: auto;
  display: grid;
  grid-template-columns: 41% 1fr;
  grid-template-rows: min-content 1fr;

  @include viewport-tablet--m {
    grid-template-columns: 1fr;
    grid-template-rows: min-content 1fr max-content;
    padding-left: 70px;
    padding-right: 65px;
  }

  @include viewport-tablet--s {
    padding-left: 10px;
    padding-right: 10px;
  }

  h2 {
    font-family: $font-family-saira-condensed;
    color: $color-bahama-blue;
    font-size: 100px;
    line-height: 90%;
    text-transform: uppercase;
    grid-column: 1 / 3;
    grid-row: 1 / 2;
    margin-top: 72px;
    margin-bottom: 72px;
    max-width: 1600px;
    font-weight: 500;

    @include viewport-tablet--m {
      grid-column: 1 / 2;
      font-size: 60px;
    }

    @include viewport-tablet--s {
      font-size: 30px;
      margin-top: 20px;
      margin-bottom: 32px;
    }
  }
}

.news-block__image {
  grid-column: 1 / 2;
  grid-row: 2 / 3;
  max-width: 705px;

  @include viewport-tablet--m {
    grid-column: 1 / 2;
    max-width: none;
    width: 100%;
    height: 320px;
    margin-bottom: 80px;
  }

  @include viewport-tablet--s {
    margin-bottom: 32px;
  }

  img {
    height: 100%;
    object-fit: cover;

    @include viewport-tablet--m {
      grid-column: 1 / 2;
      max-width: none;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.news-block__text {
  padding-left: 25px;
  margin-top: -14px;
  padding-right: 15px;

  @include viewport-tablet--m {
    grid-row: 3 / 4;
    padding-left: 0;
    padding-right: 0;
  }

  p {
    font-family: $font-family-roboto;
    font-weight: 300;
    letter-spacing: 0.03em;
    line-height: 150%;
    color: $color-nero;
    margin-top: 6px;
    margin-bottom: 6px;
    font-size: 14px;
  }

  h3 {
    font-family: $font-family-saira-condensed;
    font-weight: 500;
    line-height: 90%;
    color: $color-nero;
    margin-top: 34px;
    margin-bottom: 21px;
    font-size: 26px;
  }

  a {
    color: $color-rust;
    font-weight: 500;
    text-decoration: underline;

    &:hover {
      opacity: 0.8;
      transition: all $default-transition-settings;
    }
  }
}
