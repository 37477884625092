.offer-card {
  display: flex;
  justify-content: center;
  align-items: center;

  flex-grow: 1;

  max-width: 547px;
  min-height: 440px;

  padding: 50px;

  box-sizing: border-box;

  font-family: $font-family-roboto;
  font-weight: 300;
  color: $color-nero;

  background-color: $color-snow;
  box-shadow: 0 26px 40px rgba(0, 0, 0, 0.05);

  h3 {
    font-family: $font-family-saira-condensed;
    font-weight: 500;
    font-size: 26px;
    line-height: 90%;

    margin-top: 0;
    margin-bottom: 20px;
  }

  small {
    font-size: 12px;
    line-height: 110%;
    letter-spacing: 0.03em;
    opacity: 0.8;

    margin-bottom: 21px;
  }

  p {
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.03em;

    margin-top: 23px;
    margin-bottom: 45px;

    b {
      font-weight: 400;
    }
  }

  a {
    padding-bottom: 10px;

    &::after {
      bottom: -4px;
    }
  }

  @include viewport-tablet--m {
    max-width: unset;
    min-height: unset;

    padding: 37px 24px;

    box-sizing: border-box;

    box-shadow: none;
    border: 1px solid $color-white-smoke;

    h3 {
      margin-top: 0;
    }

    small {
      letter-spacing: normal;
    }

    a {
      padding-bottom: 2vw;
    }
  }

  @include viewport-mobile {
    align-items: flex-start;
    justify-content: start;

    padding-right: 20px;

    h3 {
      font-size: 20px;
      line-height: 18px;

      margin-bottom: 15px;
    }

    small {
      font-size: 12px;
      line-height: 13px;
      letter-spacing: 0.3px;

      margin-bottom: 20px;
    }

    p {
      font-size: 14px;
      line-height: 21px;

      margin-top: 18px;
      margin-bottom: 22px;
    }

    a {
      margin-bottom: 3px;
      padding-bottom: 8px;
    }
  }
}

.offer-card__container {
  display: flex;
  flex-direction: column;

  max-width: 447px;

  @include viewport-tablet--m {
    max-width: unset;
  }
}

.offer-card__line {
  width: 258px;
  height: 1px;
  background-color: $color-nero;

  @include viewport-tablet--m {
    width: 22vw;
    max-width: 258px;
  }
}
