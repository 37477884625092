.slider-controls {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  width: 108px;

  &--news {
    position: absolute;
    right: 85px;
    top: 23px;
    width: 120px;

    button {
      width: 53px;
    }
  }
}

@include viewport-tablet--s {
  .slider-controls--news {
    right: 0;
    top: 7px;
    width: 108px;

    button {
      width: 43px;
    }
  }
}

.slider-controls__btn {
  display: flex;
  align-items: center;

  transition: all $default-transition-settings;

  cursor: pointer;

  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;

  border: none;

  font-size: 0;

  width: 43px;
  height: 16px;

  &--prev {
    span {
      display: block;
      background-image: url("../img/svg/slide-left.svg");
      height: 14px;
      width: 43px;
      margin-left: -7px;
    }
  }

  &--next {
    span {
      display: block;
      background-image: url("../img/svg/slide-left.svg");
      transform: rotate(180deg);
      width: 43px;
      height: 14px;
      margin-top: -2px;
      margin-left: -5px;
    }

    position: relative;

    &::after {
      z-index: -8;
      top: -1px;
      left: -6px;
      width: 16px;
      height: 16px;
      content: "";
      position: absolute;
      border-radius: 50%;
      background-color: $color-bahama-blue;
      transition: all $default-transition-settings;
    }

    &:hover::after {
      background-color: $color-rust;
    }
  }

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.6;
  }
}
